/* styles.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  overflow-x: hidden !important; /* needed for slide hover effect */
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

.footer {
  background-color: #F5F5F5;
  padding: 2rem 5rem;
}

.footer h3 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}

.footer p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0.5rem;
  
}

.header {
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 5rem;
}

.header .logo {
  height: 21px;
  width: auto;
}

.header ul {
  margin: 0;
  padding: 0;
}

.header ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 1rem;
}

.header ul li a {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-decoration: none;
  color: black !important;
}

.title {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 600;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.subtitle {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
  margin-bottom: 3rem;
}

/* Add these styles to your existing CSS */

/* Mobile Menu Styles */
.mobile-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 4px 0;
  transition: 0.4s;
}

.menu {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu li {
  margin-left: 1rem;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;
    position: relative;
    z-index: 999;
  }

  .menu {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #F5F5F5;
    width: 100%;
    padding: 1rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .menu li {
    margin-left: 0 !important;
    display: block;
    text-align: left;
    width: 100%;
  }

  .menu li a {
    display: block;
    padding: 1rem;
  }

  .menu.open {
    display: flex;
  }

  .mobile-menu {
    display: block;
  }

  .title {
    font-size: 32px;
  }
  
  .subtitle {
    font-size: 16px;
  }

}

.hero {
  padding: 0 1rem;
}

.content {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .content {
    scale: 2.5;
    padding: 25% 0;
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  padding-top: 4rem;
}

*:focus {
  outline: none;
}

.badge_container {
  position: relative;
}

@media (max-width: 768px) {
  .badge_modern,
  .badge_trading {
    scale: 0.25 !important;
    top: -25% !important;
  }
}

.badge_modern,
.badge_trading {
  background-color: #004F59;
  border-radius: 50%;
  color: white;
  position: absolute;
  width: 87px;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.badge_modern {
  top: 10px;
  left: -20px;
}

.badge_trading {
  top: 10px;
  right: -20px;
}

@media (max-width: 1200px) {
  .badge_modern,
  .badge_trading {
    scale: 0.75;
  }
}

.desks {
  z-index: 50;
}

.attachments {
  z-index: 100;
  /* overflow: visible; */
}

/* glide config */
.glide__slides {
  overflow: visible !important; /* so badge overflows */
}

.glide__track {
  overflow: visible !important;
}

.glide__slide img {
  width: 100%;
  height: auto;
}

.glide__slide {
  transition: opacity 0.3s, transform 0.5s, scale 0.5s;
}

.glide__slide:not(.glide__slide--active) {
  opacity: 0.5;
}

.glide__slide.modern,
.glide__slide.trading {
  z-index: 50;
}

.glide__slide.modern:not(.glide__slide--active) {
  opacity: 0.25;
  scale: 0.75;
  margin-right: calc(-35% + 5px) !important;
  margin-left: 35% !important;
  z-index: 1 !important;
}

.glide__slide.trading:not(.glide__slide--active) {
  opacity: 0.25;
  scale: 0.75;
  margin-left: -35% !important;
  z-index: 1 !important;
}

.animate_up {
  transition: transform 0.5s ease;
  transform: translateY(0);
  visibility: hidden;
}

.selected {
  transform: translateY(-100%); /* Adjust the distance as needed */
  visibility: visible !important;
}

.item_background {
  background-size: contain;
  background-position: center;
  transition: opacity 2s ease;
  position: relative;
}

.item_background.active {
  opacity: 0.25;
}

.glide__track .left {
  position: absolute;
  left: 35%;
  bottom: 10%;
  width: 40px;
}

.glide__track .up {
  position: absolute;
  top: 40%;
  right: 35%;
  width: 40px;
}

@media (max-width: 768px) {
  .glide__track .left,
  .glide__track .up {
    scale: 0.25 !important;
  }

  .glide__track .left {
    bottom: 0;
  }

  .glide__track .up {
    top: 10%;
  }
}